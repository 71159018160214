.MusicBar {
  position: fixed;
  bottom: 64px;
  background-color: rgba(44,44,44,0.9);
  width: 96vw;
  margin-left: 1vw;
  border-radius: 4px;
  padding:  1vw;
  color: white;
}

.icon-image {
  width: 32px; 
  height: 32px;
  color: white;
}

.bar-text {
  width: 97%;
}