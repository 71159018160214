.HomeGrid {
  max-height: 100vh;
  overflow-y: hidden;
}
.HomeGrid .stack {
  margin: 24px;
}

.HomeGrid .stack.first {
  margin-top: 0px;
}

.divider {
  margin-bottom: 18px !important; 
  margin-top: 0px !important; 
  font-size: 1.2rem;
}

.home-grid-scroll {
  /* max-height: calc(100vh - var(--footer-offset));
  overflow-y: auto; */
  max-height: 100vh;  
  overflow-y: hidden;
  margin-left: 4px;
}

.home-play-stack {
  max-width: calc(100% - 100px);
}




.runnable img.carousel-show,
.runnable img.carousel-hide {
  transition: left 0.5s ease-in-out;
}

img.carousel-show {
  left: 0px !important;
}
img.carousel-hide {
  left: 100% !important;
}


.running img.carousel-show {
  left: -100% !important;
}

.running img.carousel-hide {
  left: 0px !important;
}


.text {
  color: white;
  z-index: 11;
  position: absolute;
  bottom: 0;
  right: 24px;
  opacity: 1 !important;
  transition: bottom 0.5s linear;
  background-color: rgba(0,0,0,0.7);
  padding: 8px 20px;
  border-radius: 4px 4px 0px 0px;
}

.running .text {
  bottom: -140px;
}