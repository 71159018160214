.MusicCard {
  position: relative;
}
.MusicCard.full {
  height: calc(100vh - 48px); 
}

.slide-box {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 240px;
}

.slide-box.tall { 
  height: 300px;
}

.slide-box.slidephoto { 
  position: absolute !important;
  top: 0;
  left: 0;
  transition: left 2.4s ease-in;
}

.slide-box.slidephoto.slide { 
  left: -500px;
}

.play-button {
  margin: 0 6px;
}
.play-button.large {
  width: 40px;
  height: 40px;
}
.play-button.play {
  width: 40px;
  height: 40px;
}
.play-button.large.play {
  width: 64px;
  height: 64px;
}

.music-card-content {
  padding: 4px 16px !important;
}


@keyframes slide {
  0% {left: 0px;}
  100% {left: -400px;}
}

.music-card-eq {
  /* border: dotted 1px red; */
  padding: 0 6px;
}