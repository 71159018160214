body {
  margin: 0;
  overflow: hidden;
  --color-minor: ivory;
  --color-hilite: aliceblue;
  --normal-link: #222;
  --hover-link: royalblue;
  --active-link: royalblue;
  --border-hilite: #37a;
  --header-offset: 124px;
  --thumbnail-image-height: 180px;
  --tune-grid-columns: 156px 42px 42px;


  --card-shadow:  rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;

  --main-link-color: #222;
  --gray-text-color: #667;
  --accent-shadow-color: hsla(0,0%,78.4%,0.4);
  --toolbar-text-color: #fff;
  --line-item-normal-color: #fff;
  --line-item-active-color: #faebd7;
  --accent-text-color: #ff0080;
  --dark-window-color: #1f3d55;
  --light-window-color: #406172;
  --hilite-text-backcolor: #ffd800;
  --hilite-text-forecolor: #37a;
  --row-even-color: #eef4fe;
  --thumb-item-cell-width: 180px;
  --thumb-item-cell-height: 230px;
  --thumb-item-image-size: 172px;
  --sort-menu-width: inherit;

}

.App {
  text-align: left;
  margin: 0;
  overflow: hidden;
}
 

.left-grid {
  background-color: white;
  box-shadow: 1px 1px 2px rgba(155,155,155,0.6), 2px 4px 6px rgba(222,222,222,0.4);
}

.bold {
  font-weight: 600;
}
.wide {
  width: 100% !important;
}
.high {
  height: 100vh !important;
}
.debug {
  border: dotted 1px rgb(155, 147, 148);
}
.flex {
  display: flex;
}
.flex.center {
  align-items: center;
}
.flex.column {
  flex-direction: column;
}
.flex.column {
  flex-direction: column;
}
.flex.middle {
  justify-content: center;
}
.flex.between {
  justify-content: space-between;
}
.flex.left {
  justify-content: left;
}
.flex .auto {
  margin-left: auto;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.no-wrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.link {
  cursor: pointer;
  text-decoration: none;
  color: var(--main-link-color);
}

.link:hover { 
  color: var(--hover-link);
  text-decoration: underline;
}

.link:active { 
  color: var(--active-link);
}
 
.header-panel {
  height: calc (320px - var(--scroll-offset));
  /* outline: dotted 1px red; */
  transition: height 0.4s linear;
  overflow: hidden;
}

.header-panel.off {
  height: 60px; 
}

.button-icon {
  margin: 0 8px;
}

.eq-outer-label {
  max-height: 60px;
  width: 400px;
}

#portal {
  position: absolute;
  top: 0;
  left: 0; 
  background-color: #fff;
}

#portal iframe { 
  width: 100vh;
  height: 100vw;  
}

.header-avatar {
  margin: 12px;
}
.header-button {
  margin-right: 12px !important;
}
.header-chip {
  margin: 8px 0 8px 12px !important;
  text-transform: capitalize;
}
.header-chip.tiny {
  padding-left: 12px;
  text-transform: capitalize;
}

.cropped {
  overflow: hidden;
}

.cropped.small {
  max-height: 80px !important;
}

.force-right {
  position: absolute !important;
  right: 20px;
  top: 8px;
}

.caps {
  text-transform: capitalize;
}

/*
.level-1 {
  border: solid 2px lime;
}

.level-2 {
  border: solid 2px blue;
}

.level-3 {
  border: solid 2px red;
  max-height: 96vh;
  overflow: hidden;
}

.level-4 {
  border: solid 6px lime;
}

.level-5 {
  border: dotted 2px lime;
} 
 */