.QueueDrawer {position: relative;}
.queue-header {
  width: calc(100% - 64px);
}

.fixed-list {
  position: fixed;
  background-color: #fff;
  top: 0;
  padding: 12px 0;
}
.unfixed-list {
  background-color: #fff;
  position: fixed;
  top: 124px;
   height: calc(100% - 124px);
  overflow: auto;
}