@keyframes ldio-2b54pwhxpd3 {
  0% { transform: translate(12px,80px) scale(0); }
 25% { transform: translate(12px,80px) scale(0); }
 50% { transform: translate(12px,80px) scale(1); }
 75% { transform: translate(80px,80px) scale(1); }
100% { transform: translate(148px,80px) scale(1); }
}
@keyframes ldio-2b54pwhxpd3-r {
  0% { transform: translate(148px,80px) scale(1);}
100% { transform: translate(148px,80px) scale(0); }
}
@keyframes ldio-2b54pwhxpd3-c {
  0% { background: #e15b64 }
 25% { background: #abbd81 }
 50% { background: #f8b26a }
 75% { background: #f47e60 }
100% { background: #e15b64 }
}
.ldio-2b54pwhxpd3 div {
 position: absolute;
 width: 40px;
 height: 40px;
 border-radius: 50%;
 transform: translate(80px,80px) scale(1);
 background: #e15b64;
 animation: ldio-2b54pwhxpd3 1s infinite cubic-bezier(0,0.5,0.5,1);
}
.ldio-2b54pwhxpd3 div:nth-child(1) {
 background: #f47e60;
 transform: translate(148px,80px) scale(1);
 animation: ldio-2b54pwhxpd3-r 0.25s infinite cubic-bezier(0,0.5,0.5,1), ldio-2b54pwhxpd3-c 1s infinite step-start;
}.ldio-2b54pwhxpd3 div:nth-child(2) {
 animation-delay: -0.25s;
 background: #e15b64;
}.ldio-2b54pwhxpd3 div:nth-child(3) {
 animation-delay: -0.5s;
 background: #f47e60;
}.ldio-2b54pwhxpd3 div:nth-child(4) {
 animation-delay: -0.75s;
 background: #f8b26a;
}.ldio-2b54pwhxpd3 div:nth-child(5) {
 animation-delay: -1s;
 background: #abbd81;
}
.loadingio-spinner-ellipsis-hyc4zo1vieb {
 width: 200px;
 height: 200px;
 display: inline-block;
 overflow: hidden;
 background: #ffffff;
}
.ldio-2b54pwhxpd3 {
 width: 100%;
 height: 100%;
 position: relative;
 transform: translateZ(0) scale(1);
 backface-visibility: hidden;
 transform-origin: 0 0; /* see note above */
}
.ldio-2b54pwhxpd3 div { box-sizing: content-box; }
/* generated by https://loading.io/ */