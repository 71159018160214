.EqBar {
  height: 40px;
  border: solid 1px #e0e0e0;
  /* box-shadow: var(--card-shadow); */
  position: relative;
}

.grid-mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  z-index: 22;
}
