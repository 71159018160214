.TypeMenu {}
.type-menu {
  width: 90px;
  transition: width 0.2s ease-in-out;
}

.type-menu.small {
  width: 56px; 
}

.type-menu.off {
  width: 0 !important;
  overflow: hidden;
}

.type-button {
  width: 40px;
  transition: width 0.2s ease-in-out;
}
.type-button.off {
  width: 0 !important;
  overflow: hidden;
}
