.ListSlider { 
  width: calc(100% - 24px); 
}

.time-box {
  border: solid 1px var(--border-hilite);
  background-color: var(--color-hilite);
  color: #444;
  border-radius: 4px;
  font-size: 0.8rem;
  padding: 0 6px;
}