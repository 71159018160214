.TubeSocket {
  padding: 24px;
}

.socket-preview {
  width: 300px;
  height: auto;
  margin: 12px;
  border-radius: 8px;
}

.socket-status {
  margin: 12px;
}